// import { gsap } from "gsap"

(window as any)["motion-agency-com"] = {
    loaded: false,
    sliderImage: 1,
    transitionProccess: false,
}


async function initApp() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
        (window as any)["motion-agency-com"].slider = {
            publicMethods: {
                goTo: function() { }
            }
        }
    }
    else {
        // Slider
        const { default: WebGLSlider } = await import('../external/motion-ui/motion-ui/plugins/webGLSlider');
        (window as any)["motion-agency-com"].slider = WebGLSlider({
            imagesWidth: 1400,
            imagesHeight: 1000,
            images: [
                "/assets/img/slider/1.avif",
                "/assets/img/slider/2.avif",
                "/assets/img/slider/3.avif",
                "/assets/img/slider/4.avif",
                "/assets/img/slider/5.avif",
            ],
            dispImages: '/assets/img/displacement/1.jpg',
            container: '#slider',
            angle: 30
        });
        (window as any)["motion-agency-com"].slider.init()
    }

    // ScrollBar
    const { default: ScrollBar } = await import('../external/motion-ui/motion-ui/plugins/scrollBar');
    (window as any)["motion-agency-com"].scrollbar = new ScrollBar({
        element: "#sceleton-bottom-right",
        config: {
            global: {
                parentElement: "#sceleton-bottom-right"
            }
        },
    });

    // Navigation
    const { default: Navigation } = await import('./include/navigation');
    (window as any)["motion-agency-com"].navigation = new Navigation()

    // Animations
    const { default: Animations } = await import('./include/animations');
    Animations.logoTransition()

    // Menu
    const { default: Menu } = await import('./include/menu');
    (window as any)["motion-agency-com"].menu = new Menu();

    // Preload
    const { default: gsap } = await import('gsap');
    gsap.to(
        "#loader",
        {
            opacity: 0,
            duration: 0.3,
            display: "none"
        }
    );
}
initApp();


// import Animations from "./include/animations"
// import Menu from "./include/menu"
// import ScrollBar from "../external/motion-ui/motion-ui/plugins/scrollBar";
// import Navigation from "./include/navigation"



// Animations.logoTransition()

// const navigation = new Navigation()
// const menu = new Menu();

// (window as any)["motion-agency-com"].navigation = navigation;
// (window as any)["motion-agency-com"].menu = menu;
// (window as any)["motion-agency-com"].scrollbar = scrollBar;

// (window as any).addEventListener('load', function(event: any) {
//     (window as any)["motion-agency-com"].loaded = true;
//     // setTimeout(() => {
//     gsap.to(
//         "#loader",
//         {
//             opacity: 0,
//             duration: 0.3,
//             display: "none"
//         }
//     )
//     // }, 5000);
// })

// setTimeout(() => {
//     // menu.close()
//     // Slider.publicMethods.goTo(2)
// }, 5000);
